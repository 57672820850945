import React from 'react';
import Select from 'react-select';

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid white',
    padding: 20,
    color: 'black',
  }),

  input: (provided, state) => ({
    ...provided,
  }),

  control: (provided, { selectProps: { width } }) => ({
    ...provided,
  }),
};

const Dropdown = ({ options, onChange }) => (
  <Select styles={customStyles} options={options} onChange={onChange} />
);

export default Dropdown;
