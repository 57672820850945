import React from 'react';
import styled from 'styled-components';
import book from '../assets/book.png';
import planet from '../assets/planet.png';
import rocket from '../assets/rocket.png';
import satellite from '../assets/satellite.png';
import telescope from '../assets/telescope.png';

const CardWrapper = styled.div`
  a,
  a:hover,
  a:focus,
  a:active {
    text-decoration: none;
    color: inherit;
  }

  max-width: 400px;
`;

const Title = styled.div`
  font-family: nasalization, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  color: #0b3d91;
`;

const Description = styled.div`
  font-size: 16px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: repeat(autofit, minmax(50px, 1fr));
  grid-gap: 0.5rem;
`;

const ImageSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageWrapper = styled.div`
  margin: 5px 0;
`;

const DateWrapper = styled.div`
  font-size: 13px;
  text-align: center;
  color: #fc3d21;
`;

const Card = ({ title, url, description, icon, startDate, endDate }) => {
  const getImage = () => {
    switch (icon) {
      case 'book':
        return (
          <img src={book} alt="Image of a book" width="80" height="auto" />
        );
      case 'planet':
        return (
          <img src={planet} alt="Image of Saturn" width="80" height="auto" />
        );
      case 'spaceship':
        return (
          <img
            src={rocket}
            alt="Image of a spaceship"
            width="80"
            height="auto"
          />
        );
      case 'probe-orbiter':
        return (
          <img
            src={satellite}
            alt="Image of a satellite"
            width="80"
            height="auto"
          />
        );
      case 'telescope':
        return (
          <img
            src={telescope}
            alt="Image of a telescope"
            width="80"
            height="auto"
          />
        );
      default:
        return (
          <img
            src={rocket}
            alt="Image of a spaceship"
            width="80"
            height="auto"
          />
        );
    }
  };
  return (
    <CardWrapper>
      <a href={url}>
        <ContentWrapper>
          <div></div>
          <Title>{title}</Title>
          <ImageSectionWrapper>
            <ImageWrapper>{getImage()}</ImageWrapper>
            <DateWrapper>
              {startDate ? `${startDate} - ${endDate}` : endDate}
            </DateWrapper>
          </ImageSectionWrapper>
          <Description>{description}</Description>
        </ContentWrapper>
      </a>
    </CardWrapper>
  );
};

export default Card;
