import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactLoading from 'react-loading';
import Fuse from 'fuse.js';
import './App.css';
import Card from './components/Card';
import Dropdown from './components/Dropdown';

const AppWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  padding: 20px;
  background-color: #000000;
  color: #ffffff;
  font-family: vox, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 15px;

  background-color: #000000;
  background-image: radial-gradient(
      white,
      rgba(255, 255, 255, 0.2) 2px,
      transparent 40px
    ),
    radial-gradient(white, rgba(255, 255, 255, 0.15) 1px, transparent 30px),
    radial-gradient(white, rgba(255, 255, 255, 0.1) 2px, transparent 40px),
    radial-gradient(
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.1) 2px,
      transparent 30px
    );
  background-size: 550px 550px, 350px 350px, 250px 250px, 150px 150px;
  background-position: 0 0, 40px 60px, 130px 270px, 70px 100px;
`;

const AppTitle = styled.div`
  font-size: 2.5rem;
  font-family: nasalization, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  margin-bottom: 3rem;
`;

const CardsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  grid-gap: 4rem;
  justify-items: center;
`;

const HeroWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 200px 390px;
  grid-gap: 1.5rem;
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 3rem;

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 8px;
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const StyledInput = styled.input`
  height: 36px;
  width: 100%;
  margin-right: 10px;
  font-family: inherit;
  border: none;
  border-radius: 4px;
  padding-left: 10px;
  font-weight: inherit;
`;

const StyledButton = styled.button`
  background-color: #d4141b;
  color: #ffffff;
  font-family: inherit;
  border: none;
  border-radius: 4px;
  padding: 0 10px;
  font-weight: inherit;
`;

const Loader = ({ type, color }) => (
  <ReactLoading type={type} color={color} height={667} width={375} />
);

function App() {
  const [entries, setEntries] = useState([]);
  const [filteredEntries, setFilteredEntries] = useState([]);
  const [searchedEntries, setSearchedEntries] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch(
      'https://ekyr0hoqmb.execute-api.us-east-1.amazonaws.com/KilledByNasa/nasaprojects'
    )
      .then((response) => response.json())
      .then((data) => {
        const body = JSON.parse(data.body);
        setEntries(body);
        setFilteredEntries(body);
        setSearchedEntries(body);
      })
      .then(() => setIsLoading(false));
  }, []);

  const options = [
    { label: 'All', value: 'all' },
    { label: 'Study', value: 'book' },
    { label: 'Planetary', value: 'planet' },
    { label: 'Spaceship', value: 'spaceship' },
    { label: 'Probe/Orbiter', value: 'probe-orbiter' },
    { label: 'Telescope', value: 'telescope' },
  ];

  const fuseOptions = {
    keys: ['name', 'description'],
  };

  const searchData = (filteredEnt = filteredEntries) => {
    const fuse = new Fuse(filteredEnt, fuseOptions);
    if (!searchQuery) {
      setSearchedEntries(filteredEnt);
      return;
    }

    const result = fuse.search(searchQuery);
    if (!result) {
      setSearchedEntries([]);
      return;
    }
    setSearchedEntries(result);
  };

  return (
    <AppWrapper>
      <AppTitle>Killed By NASA</AppTitle>
      <HeroWrapper>
        <Dropdown
          options={options}
          onChange={(selection) => {
            let newFilteredEntries = entries;
            if (selection.value !== 'all') {
              newFilteredEntries = entries.filter(
                (entry) => entry.icon === selection.value
              );
            }
            setFilteredEntries(newFilteredEntries);
            searchData(newFilteredEntries);
          }}
        />
        <SearchWrapper>
          <StyledInput
            placeholder="Search..."
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <StyledButton onClick={() => searchData(filteredEntries)}>
            Search
          </StyledButton>
        </SearchWrapper>
      </HeroWrapper>
      <CardsSection>
        {isLoading ? (
          <>
            <Loader type="bubbles" color="#ffffff" />
          </>
        ) : (
          <>
            {searchedEntries.map((entry) => {
              let newEntry;
              if (entry.item) {
                newEntry = entry.item;
              } else {
                newEntry = entry;
              }
              return (
                <Card
                  title={newEntry.name}
                  url={newEntry.url}
                  description={newEntry.description}
                  icon={newEntry.icon}
                  startDate={newEntry.start_date}
                  endDate={newEntry.end_date}
                />
              );
            })}
          </>
        )}
      </CardsSection>
    </AppWrapper>
  );
}

export default App;
